import { FC, useContext, useEffect, useState } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {useUpdateOneContentMutation, ContentUpdateInput, ContentStatus,ContentCompleteByType, useGetOneContentQuery, Content, Maybe, User, useGetUsersListQuery, SortOrder, UserType, useGetOneContentLazyQuery, ContentType} from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, Container, TextField, Switch } from '@mui/material';
import Typography from '../../../DesignSystem/atoms/Typography/Typography';
import { handleNestedValues, USStatesAndTerritoriesAbbreviations } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton'
import { useParams } from 'react-router-dom-v5-compat';
import { EstateComponentContext } from '../../../../providers/EstateComponentProvider';
import LoadingOverlay from '../../../../views/LoadingOverlay';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';


type UpdateOneContentFormProps = {
  testId?: string
}

type ContentQueryReturn = {
  __typename?: "Content" | undefined;
  id: string;
  completeByType: ContentCompleteByType;
  completeBy: any;
  displayDate: string;
  body: string;
  componentId?: string | null | undefined;
  header: string;
  status: ContentStatus;
  user?: { __typename?: "User" | undefined; id: string; firstName?: string | null | undefined; lastName?: string | null | undefined; avatar?: string | null | undefined; } | null | undefined;
  userId?: string | null | undefined;
  type: ContentType | null | undefined;
} 

const UpdateOneContentForm: FC<UpdateOneContentFormProps> = ({testId}) => {
  const { selectedComponentContentCardId } = useContext(EstateComponentContext)
  const { estateComponentDetailsListRefetch } = useContext(EstateComponentContext)
  const [getOneContent] = useGetOneContentLazyQuery();

  const [entity, setEntity] = useState<ContentQueryReturn | null | undefined>();
  
  const {refetch} = useGetOneContentQuery({
    variables: {where: {id: selectedComponentContentCardId}},
    onCompleted: (data) => {
      if (!data) return;
      
      setEntity({
        ...data?.getContent,
        type: data?.getContent?.type || null, 
      } as ContentQueryReturn)
    },
    onError: (e: ApolloError) => {
      console.error("Failed getOneContentQuery call");
      console.error(e.message);
      alert(e.message);
    },
  },
  );

  useEffect(() => {
    setEntity(null)
    refetch()
  }, [selectedComponentContentCardId])
  
      
  const { setDisplayModal, setModalContents } = useContext(LayoutContext)
  const [ updateOneContentMutation ] = useUpdateOneContentMutation();
  const [ options, setOptions] = useState<any>({
    "component": [
      {
        "id": 1,
        "displayValue": "Option1",
      },
    ],
    "status": [
      {
        "submitValue": "COMPLETED",
        "displayValue": "Completed",
      },
      {
        "submitValue": "IN_PROCESS",
        "displayValue": "InProcess",
      },
    ],
    "user": [],
    "completeByType": [
      {
        "submitValue": "EXACT",
        "displayValue": "Exact",
      },
      {
        "submitValue": "FUZZY",
        "displayValue": "Fuzzy",
      },
      {
        "submitValue": "MONTH",
        "displayValue": "Month",
      },
    ],
    "type": [
      {
        "submitValue": "KEY_DATE",
        "displayValue": "Key Date",
      },
      {
        "submitValue": "RECENT_ACTIVITY",
        "displayValue": "Recent Activity",
      },
    ],
  })

  // call the user list
  useGetUsersListQuery({
    variables: {
      orderBy: {firstName: { sort: SortOrder.Asc }},
      where: {type: {equals: UserType.Internal}},
    },
    onCompleted: (data: any) => {
      setOptions((prevOptions: any) => ({
        ...prevOptions,
        user: data.users.map((user: any) => ({
          submitValue: user.id,
          displayValue: `${user.firstName} ${user.lastName}`,
        })),
      }));
    },
  });

  const handleSubmit = async (
    values: ContentUpdateInput,
    { resetForm }: { resetForm: any },
  ) => {
    //pull in related mutation
    const nestedValues = handleNestedValues(values, formData) as ContentUpdateInput
    nestedValues.type = nestedValues.type || null;

    updateOneContentMutation({
      variables: {
        data: nestedValues,
      
        where: {id: entity?.id},
        
      },
      onCompleted: (data) => {
        estateComponentDetailsListRefetch();
        getOneContent({
          variables: {where: {id: selectedComponentContentCardId}},
          onCompleted: (data) => {
            setEntity(data.getContent as ContentQueryReturn)
          },
          onError: (e: ApolloError) => {
            console.error("Failed getOneContentQuery call");
            console.error(e.message);
            alert(e.message);
          },
        });
      }, 
      onError: (e: ApolloError) => {
        console.error("Failed updateOneContentMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
  }

  const initialValues = {
    component: entity?.componentId || '',
    status: entity?.status || ContentStatus.Completed,
    user: entity?.userId || '',
    header: entity?.header || '',
    body: entity?.body || '',
    completeBy: entity?.completeBy ? dayjs(entity.completeBy) : null,
    completeByType: entity?.completeByType || ContentCompleteByType.Exact,
    type: entity?.type || null,
  };

  const validationSchema = Yup.object().shape({
    component: Yup.string(),
    status: Yup.string(),
    user: Yup.string(),
    header: Yup.string().required("header is required").max(50, 'Header must be 50 characters or less'),
    body: Yup.string().required("body is required"),
    completeBy: Yup.date().required("completeBy is required"),
    completeByType: Yup.string(),
    type: Yup.string().nullable(),
  });

  if(!entity) return <LoadingOverlay />;

  return (
    <div data-testid={testId} className='updateOneContentFormContainer'>
      <Typography font='UI1' variant='H4' className='updateOneContentFormHeader'>
        Update Detail Card
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors, touched, submitCount, setFieldValue, values,
        }) => (
          <Form noValidate>
            <Container className="formContainer">
              <Container className="formSection">
                <Field
                  className={"componentInput fieldInput textInput"}
                  required={true}
                  id={"component"}
                  label={"component"}
                  name={"component"}
                  type={"hidden"}
                  autoComplete={"component"}
                  // as={TextField}
                  error={submitCount > 0 && touched.component&& !!errors.component}
                />

                <div className={"inputWrapper statusFieldWrapper dropdownInputWrapper"} key={"status1"} data-testid={testId}>
                  <Autocomplete
                    options={options.status || []}
                    getOptionLabel={(option: {submitValue: string, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"statusInput fieldInput dropdownInput"}
                        required={false}
                        id={"status"}
                        label={"Status"}
                        name={"status"}
                        type={"text"}
                        autoComplete={"status"}
                        as={TextField}
                        error={submitCount > 0 && touched.status && !!errors.status}                        
                      />
                    }
                    value={options.status.find((option: any) => option.submitValue === values.status)}
                    onChange={(_, value) => setFieldValue("status", value?.submitValue)}
                  />
                  <ErrorMessage name={"status"} component="div" className="inputErrorMessage"/>
                </div>

                <div className={"inputWrapper userFieldWrapper dropdownInputWrapper"} key={"user1"} data-testid={testId}>
                  <Autocomplete
                    options={options.user || []}
                    getOptionLabel={(option: {submitValue: string, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"userInput fieldInput dropdownInput"}
                        required={false}
                        id={"user"}
                        label={"Assign User"}
                        name={"user"}
                        type={"text"}
                        autoComplete={"user"}
                        as={TextField}
                        error={submitCount > 0 && touched.user && !!errors.user}                        
                      />
                    }
                    value={options.user.find((option: any) => option.submitValue === values.user)}
                    onChange={(_, value) => setFieldValue("user", value?.submitValue)}
                  />
                  <ErrorMessage name={"user"} component="div" className="inputErrorMessage"/>
                </div>

                <div className={"inputWrapper userFieldWrapper dropdownInputWrapper"} key={"contentType"} data-testid={testId}>
                  <Autocomplete
                    options={options.type || []}
                    getOptionLabel={(option: {submitValue: string, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"typeInput fieldInput dropdownInput"}
                        required={false}
                        id={"type"}
                        label={"Select Type"}
                        name={"type"}
                        type={"text"}
                        autoComplete={"type"}
                        as={TextField}
                        error={submitCount > 0 && touched.type && !!errors.type}                        
                      />
                    }
                    value={options.type.find((option: any) => option.submitValue === values.type)}
                    onChange={(_, value) => setFieldValue("type", value?.submitValue)}
                  />
                  <ErrorMessage name={"type"} component="div" className="inputErrorMessage"/>
                </div>

    
                <div className={"inputWrapper headerFieldWrapper textInputWrapper"} key={"header2"} data-testid={testId}>
                  <Field
                    className={"headerInput fieldInput textInput"}
                    required={false}
                    id={"header"}
                    label={"Header"}
                    name={"header"}
                    type={"text"}
                    autoComplete={"header"}
                    as={TextField}
                    // maxLength={50}
                    error={submitCount > 0 && touched.header && !!errors.header}
                  />
                  <ErrorMessage name={"header"} component="div" className="inputErrorMessage"/>
                </div>

                <div className={"inputWrapper bodyFieldWrapper textInputWrapper"} key={"body3"} data-testid={testId}>
                  <label>Body</label>
                  <ReactQuill
                    className={"bodyInput fieldInput textInput"}
                    value={values.body || ""}
                    onChange={(content) => setFieldValue("body", content)}
                    id={"body"}
                  />
                  {errors.body && touched.body && <div className="inputErrorMessage">{errors.body}</div>}
                </div>
                
                <div className={"inputWrapper completeByFieldWrapper textInputWrapper"} key={"completeBy4"} data-testid={testId}>
                  <Field
                    className={"completeByInput fieldInput textInput"}
                    // required={true}
                    id={"completeBy"}
                    label={"Complete By"}
                    name={"completeBy"}
                    type={"date"}
                    autoComplete={"completeBy"}
                    as={DatePicker}
                    error={submitCount > 0 && touched.completeBy && !!errors.completeBy}
                    value={values.completeBy}
                    onChange={(date: any) => setFieldValue("completeBy", date)}
                  />
                  <ErrorMessage name={"completeBy"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper completeByTypeFieldWrapper dropdownInputWrapper"} key={"completeByType5"} data-testid={testId}>
                  <Autocomplete
                    options={options.completeByType || []}
                    getOptionLabel={(option: {submitValue: string, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"completeByTypeInput fieldInput dropdownInput"}
                        required={false}
                        id={"completeByType"}
                        label={"Complete By Type"}
                        name={"completeByType"}
                        type={"text"}
                        autoComplete={"completeByType"}
                        as={TextField}
                        error={submitCount > 0 && touched.completeByType && !!errors.completeByType}                        
                      />
                    }
                    defaultValue={options.completeByType.find((option: any) => option.submitValue === initialValues.completeByType)}
                    onChange={(_, value) => setFieldValue("completeByType", value?.submitValue)}
                  />
                  <ErrorMessage name={"completeByType"} component="div" className="inputErrorMessage"/>
                </div>
              </Container>

            </Container>

            <Container className="buttonsContainer">
              <ActionButton
                ariaLabel="Submit form"
                variant="outline"
                className="submitFormButton"
                handleClick={() => {
                  setDisplayModal(false);
                  setModalContents(null);
                }}
              >
                Cancel
              </ActionButton>

              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                 Submit
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UpdateOneContentForm