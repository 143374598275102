import { FC, useContext, useState } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {useCreateOneContentMutation, ContentCreateInput, ContentStatus,ContentCompleteByType, useGetUsersListQuery, SortOrder, UserType} from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, Container, TextField, Switch } from '@mui/material';
import Typography from '../../../DesignSystem/atoms/Typography/Typography';
import { handleNestedValues, USStatesAndTerritoriesAbbreviations } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton'
import { useParams } from 'react-router-dom-v5-compat';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { EstateComponentContext } from '../../../../providers/EstateComponentProvider';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';


type CreateOneContentFormProps = {
  testId?: string
}

const CreateOneContentForm: FC<CreateOneContentFormProps> = ({testId}) => {
    
  const { setDisplayModal, setModalContents } = useContext(LayoutContext)
  const { estateComponentDetailsListRefetch } = useContext(EstateComponentContext)
  const [ createOneContentMutation ] = useCreateOneContentMutation();
  const {estateComponentId} = useParams();

  const [ options, setOptions] = useState<any>({
    "component": [
      {
        "id": estateComponentId,
        "displayValue": "Current Estate Component",
      },
    ],
    "status": [
      {
        "submitValue": "COMPLETED",
        "displayValue": "Complete",
      },
      {
        "submitValue": "IN_PROCESS",
        "displayValue": "In Progress",
      },
    ],
    "user": [],
    "completeByType": [
      {
        "submitValue": "EXACT",
        "displayValue": "Exact",
      },
      {
        "submitValue": "FUZZY",
        "displayValue": "Fuzzy",
      },
      {
        "submitValue": "MONTH",
        "displayValue": "Month",
      },
    ],
    "type": [
      {
        "submitValue": "KEY_DATE",
        "displayValue": "Key Date",
      },
      {
        "submitValue": "RECENT_ACTIVITY",
        "displayValue": "Recent Activity",
      },
    ],
  })

  // call the user list
  useGetUsersListQuery({
    variables: {
      orderBy: {firstName: { sort: SortOrder.Asc }},
      where: {type: {equals: UserType.Internal}},
    },
    onCompleted: (data) => {
      setOptions((prevOptions: any) => ({
        ...prevOptions,
        user: data.users.map((user: any) => ({
          submitValue: user.id,
          displayValue: `${user.firstName} ${user.lastName}`,
        })),
      }));
    },
  });

  const handleSubmit = async (
    values: ContentCreateInput,
    { resetForm }: { resetForm: any },
  ) => {
    //pull in related mutation
    const nestedValues = handleNestedValues(values, formData) as ContentCreateInput

    createOneContentMutation({
      variables: {data: nestedValues},
      onCompleted: (data) => {
        estateComponentDetailsListRefetch && estateComponentDetailsListRefetch();
        resetForm();
      }, 
      onError: (e: ApolloError) => {
        console.error("Failed createOneContentMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
  }

  const initialValues = {
    component: estateComponentId,
    status: ContentStatus.InProcess,
    user: "",
    header: "",
    body: "",
    completeBy: dayjs(),
    completeByType: ContentCompleteByType.Exact,
    type: null,
  };

  const validationSchema = Yup.object().shape({
    component: Yup.string(),
    status: Yup.string(),
    user: Yup.string(),
    header: Yup.string().required("header is required").max(50, 'Header must be 50 characters or less'),
    body: Yup.string().required("body is required"),
    completeBy: Yup.date().required("completeBy is required"),
    completeByType: Yup.string(),
    type: Yup.string().nullable(),
  });

  return (
    <div data-testid={testId} className='createOneContentFormContainer'>
      <Typography font='UI1' variant='H4' className='createOneContentFormHeader'>
        Add Detail Card
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue, values,
        }) => (
          <Form noValidate>
            <Container className="formContainer">
              {/* Hidden Fields */}
              
              <Container className="formSection">
                <Field
                  className={"componentInput fieldInput textInput"}
                  required={true}
                  id={"component"}
                  label={"component"}
                  name={"component"}
                  type={"hidden"}
                  autoComplete={"component"}
                  // as={TextField}
                  error={submitCount > 0 && touched.component&& !!errors.component}
                />

                <div className={"inputWrapper statusFieldWrapper dropdownInputWrapper"} key={"status1"} data-testid={testId}>
                  <Autocomplete
                    options={options.status || []}
                    getOptionLabel={(option: {submitValue: string, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"statusInput fieldInput dropdownInput"}
                        required={false}
                        id={"status"}
                        label={"Status"}
                        name={"status"}
                        type={"text"}
                        autoComplete={"status"}
                        as={TextField}
                        error={submitCount > 0 && touched.status && !!errors.status}                        
                      />
                    }
                    value={options.status.find((option: any) => option.submitValue === values.status)}
                    onChange={(_, value) => setFieldValue("status", value?.submitValue)}
                  />
                  <ErrorMessage name={"status"} component="div" className="inputErrorMessage"/>
                </div>

                <div className={"inputWrapper userFieldWrapper dropdownInputWrapper"} key={"user1"} data-testid={testId}>
                  <Autocomplete
                    options={options.user || []}
                    getOptionLabel={(option: {submitValue: string, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"userInput fieldInput dropdownInput"}
                        required={false}
                        id={"user"}
                        label={"Assign User"}
                        name={"user"}
                        type={"text"}
                        autoComplete={"user"}
                        as={TextField}
                        error={submitCount > 0 && touched.user && !!errors.user}                        
                      />
                    }
                    value={options.user.find((option: any) => option.submitValue === values.user)}
                    onChange={(_, value) => setFieldValue("user", value?.submitValue)}
                  />
                  <ErrorMessage name={"user"} component="div" className="inputErrorMessage"/>
                </div>

                <div className={"inputWrapper contentTypeFieldWrapper dropdownInputWrapper"} key={"contentType"} data-testid={testId}>
                  <Autocomplete
                    options={options.type || []}
                    getOptionLabel={(option: {submitValue: string, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"typeInput fieldInput dropdownInput"}
                        required={false}
                        id={"type"}
                        label={"Select Type"}
                        name={"type"}
                        type={"text"}
                        autoComplete={"type"}
                        as={TextField}
                        error={submitCount > 0 && touched.type && !!errors.type}                        
                      />
                    }
                    value={options.type.find((option: any) => option.submitValue === values.type)}
                    onChange={(_, value) => setFieldValue("type", value?.submitValue)}
                  />
                  <ErrorMessage name={"type"} component="div" className="inputErrorMessage"/>
                </div>
    
                <div className={"inputWrapper headerFieldWrapper textInputWrapper"} key={"header2"} data-testid={testId}>
                  <Field
                    className={"headerInput fieldInput textInput"}
                    required={true}
                    id={"header"}
                    label={"Header"}
                    name={"header"}
                    type={"text"}
                    autoComplete={"header"}
                    as={TextField}
                    error={submitCount > 0 && touched.header && !!errors.header}
                  />
                  <ErrorMessage name={"header"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper bodyFieldWrapper textInputWrapper"} key={"body3"} data-testid={testId}>
                  <label>Body</label>
                  <ReactQuill
                    className={"bodyInput fieldInput textInput"}
                    value={values.body || ""}
                    onChange={(content) => setFieldValue("body", content)}
                    id={"body"}
                  />
                  {errors.body && touched.body && <div className="inputErrorMessage">{errors.body}</div>}
                </div>
      
                <div className={"inputWrapper completeByFieldWrapper textInputWrapper"} key={"completeBy4"} data-testid={testId}>
                  <Field
                    className={"completeByInput fieldInput textInput"}
                    // required={true}
                    id={"completeBy"}
                    label={"Complete By"}
                    name={"completeBy"}
                    type={"date"}
                    autoComplete={"completeBy"}
                    as={DatePicker}
                    error={submitCount > 0 && touched.completeBy && !!errors.completeBy}
                    value={values.completeBy}
                    onChange={(date: any) => setFieldValue("completeBy", date)}
                  />
                  <ErrorMessage name={"completeBy"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper completeByTypeFieldWrapper dropdownInputWrapper"} key={"completeByType5"} data-testid={testId}>
                  <Autocomplete
                    options={options.completeByType || []}
                    getOptionLabel={(option: {submitValue: string, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"completeByTypeInput fieldInput dropdownInput"}
                        required={false}
                        id={"completeByType"}
                        label={"Complete By Type"}
                        name={"completeByType"}
                        type={"text"}
                        autoComplete={"completeByType"}
                        as={TextField}
                        error={submitCount > 0 && touched.completeByType && !!errors.completeByType}                        
                      />
                    }
                    value={options.completeByType.find((option: any) => option.submitValue === values.completeByType)}
                    onChange={(_, value) => setFieldValue("completeByType", value?.submitValue)}
                  />
                  <ErrorMessage name={"completeByType"} component="div" className="inputErrorMessage"/>
                  <ErrorMessage component={"component"} name="div" className="inputErrorMessage"/>
                </div>
              </Container>

            </Container>

            <Container className="buttonsContainer">
              <ActionButton
                ariaLabel="Submit form"
                variant="outline"
                className="submitFormButton"
                handleClick={() => {
                  setDisplayModal(false);
                  setModalContents(null);
                }}
              >
                Cancel
              </ActionButton>

              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                 Submit
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateOneContentForm